import type {
  TCommentResponse,
  TCommentsResponse,
  TCreateComment,
} from '../comment/comment.types'
import type {
  TBookmarkPostParams,
  TClapPostParams,
  TCreatePostParams,
  TCreatePostResponse,
  TCreateTopicParams,
  TCreateTopicResponse,
  TFollowTopicParams,
  TGetTopicParams,
  TGetTopicResponse,
  TPostDetail,
  TPostFooterResponse,
  TPostParams,
  TSuggestQuestion,
  TPostTrendingResponse,
  TPosts,
  TTopAuthorResponse,
  TTopicDetail,
  TTopicPopularResponse,
  TTopicTransform,
} from '.'
import { TSuggestPostResponse } from '../course'

export async function getPosts(params: TPostParams) {
  const data = await makeRequest<TPosts>('/api/posts', {
    params,
  })
  return data
}

export async function getPostsByUsername(
  params: TPostParams,
  username: string,
) {
  const data = await makeRequest<TPosts>(`/api/posts/users/${username}`, {
    params,
  })
  return data
}

export async function getTopAuthors() {
  const data = await makeRequest<TTopAuthorResponse>('/api/posts/top-authors')
  return data
}

export async function getPostsByTopicSlug(params: TPostParams, slug: string) {
  const data = await makeRequest<TTopicDetail>(`/api/topics/posts/${slug}`, {
    params,
  })
  return data
}

export async function getTopicPopulars() {
  const data = await makeRequest<TTopicPopularResponse>('/api/topics/popular')
  return data
}

export async function getPostComments(slug: string) {
  const data = await makeRequest<TCommentsResponse>(
    `/api/posts/comments/${slug}`,
  )
  return data
}

export async function postCreateComment(payload: TCreateComment) {
  const data = await makeRequest<TCommentResponse>('/api/posts/comment', {
    method: 'POST',
    body: payload,
  })
  return data
}

export async function getPostDetail(slug: string) {
  const data = await makeRequest<TPostDetail>(`/api/posts/detail/${slug}`)
  return data
}

export async function getPostsSuggestion(slug: string) {
  const data = await makeRequest<TPostFooterResponse>(
    `/api/posts/suggestion/${slug}`,
  )
  return data
}

export async function getPostsSuggestionQuestion(
  slug: string,
  limit: number = 3,
) {
  const data = await makeRequest<TSuggestQuestion[]>(
    `/api/posts/suggest-question/${slug}?limit=${limit}`,
  )
  return data
}

export async function getPostsSameAuthor(slug: string) {
  const data = await makeRequest<TSuggestPostResponse>(
    `/api/posts/the-same-author/${slug}`,
  )
  return data as unknown as TSuggestPostResponse
}

export async function getPostTrending() {
  const data = await makeRequest<TPostTrendingResponse>('/api/posts/trending')
  return data
}

export async function getTopic(params: TGetTopicParams) {
  const data = await makeRequest<TGetTopicResponse, TTopicTransform[]>(
    '/api/topics',
    {
      params,
      transform: (data) =>
        data.items.map((item) => ({ id: item.id, label: item.title })),
    },
  )
  return data
}

export async function postCreateTopic(payload: TCreateTopicParams) {
  const data = await makeRequest<TCreateTopicResponse>(
    '/api/topics/posts/create',
    {
      method: 'POST',
      body: payload,
    },
  )
  return data
}

export async function postCreatePost(payload: TCreatePostParams) {
  const data = await makeRequest<TCreatePostResponse>('/api/posts/create', {
    method: 'POST',
    body: payload,
  })
  return data
}

export async function postClapPost(payload: TClapPostParams) {
  const data = await makeRequest<TCreatePostResponse>('/api/posts/clap', {
    method: 'POST',
    body: payload,
  })
  return data
}

export async function postBookmarkPost(payload: TBookmarkPostParams) {
  const data = await makeRequest<TCreatePostResponse>('/api/posts/bookmark', {
    method: 'POST',
    body: payload,
  })
  return data
}

export async function postFollowTopic(payload: TFollowTopicParams) {
  const data = await makeRequest<TCreateTopicResponse>('/api/topics/follow', {
    method: 'POST',
    body: payload,
  })
  return data
}
